class RevealText {
  constructor(selector) {
    this.element = selector;
    this.textToReveal = this.element.nextElementSibling;
    this.btnShow = 'Czytaj dalej';
    this.btnHide = 'Zwiń tekst';
    this.init();
  }

  init() {
    this.element.addEventListener('click', (e) => {
      e.preventDefault();
      this.handleRevealText();
    });
  }

  handleRevealText() {
    if (this.textToReveal.style.maxHeight) {
      this.hideText();
    } else {
      this.showText();
    }
  }

  showText() {
    this.element.innerHTML = this.btnHide;
    this.textToReveal.classList.add('is-visible');
    this.textToReveal.style.maxHeight = `${this.textToReveal.scrollHeight}px`;
  }

  hideText() {
    this.element.innerHTML = this.btnShow;
    this.textToReveal.classList.remove('is-visible');
    this.textToReveal.style.maxHeight = null;
  }
}

export default RevealText;
