class NewsletterForm {
  constructor(selector) {
    this.element = selector;
    this.submitForm = this.element.querySelector('.js-newsletter-submit');
    this.newsletterMainList = this.element.querySelector(
      '.js-newsletter-main-list',
    );
    this.newsletterAdditionalList = this.element.querySelector(
      '.js-newsletter-additional-list',
    );
    this.newsletterRodo = this.element.querySelector('.js-newsletter-rodo');
    this.responseTextElement = this.element.querySelector(
      '.js-newsletter-response',
    );
    this.button = this.element.querySelector('.js-newsletter-submit-button');
    this.isFormValid = false;

    this.init();
  }

  init() {
    this.submitForm.addEventListener(
      'submit',
      this.handleValidation.bind(this),
    );
  }

  handleValidation(e) {
    e.preventDefault();
    this.button.disabled = true;
    this.submitForm.classList.add('is-loading');
    this.responseTextElement.innerHTML = '';

    this.isFormValid = true;
    this.validateFields(this.element.querySelector('.js-newsletter-name'));
    this.validateFields(this.element.querySelector('.js-newsletter-email'));
    this.validateFields(this.newsletterRodo);

    if (this.isFormValid) {
      this.handleSignup();
    } else {
      this.button.disabled = false;
      this.submitForm.classList.remove('is-loading');
    }
  }

  validateFields(field) {
    if (field.type === 'text') {
      this.validateIsEmpty(field, 'Ej, ale jak masz na imię?');
    }

    if (field.type === 'email') {
      this.validateIsEmpty(field, 'Uzupełnij pole email');
      if (this.isFormValid) {
        this.validateIsCorrectEmail(field, 'Hej, uciekła Ci małpa!');
      }
    }

    if (field.type === 'checkbox') {
      this.validateRequiredCheckbox(
        field,
        'Jeszcze ptaszek przy RODO i jedziemy z tym newsletterem!',
      );
    }
  }

  validateIsEmpty(field, errorMsg) {
    if (field.value.trim() === '') {
      this.responseMessage(errorMsg);
      this.isFormValid = false;
    } else {
      this.isFormValid = !!this.isFormValid;
    }
  }

  validateIsCorrectEmail(field, errorMsg) {
    const rule = /\S+@\S+\.\S+/;
    if (!rule.test(field.value)) {
      this.responseMessage(errorMsg);
      this.isFormValid = false;
    } else {
      this.isFormValid = !!this.isFormValid;
    }
  }

  validateRequiredCheckbox(field, errorMsg) {
    if (!field.checked) {
      this.responseMessage(errorMsg);
      this.isFormValid = false;
    } else {
      this.isFormValid = !!this.isFormValid;
    }
  }

  handleSignup() {
    const emailValue = this.element.querySelector('.js-newsletter-email').value;
    const nameValue = this.element.querySelector('.js-newsletter-name').value;
    const newsletterRodoValue = this.newsletterRodo.checked;
    let newsletterListsValues = this.newsletterMainList.value;
    newsletterListsValues +=
      this.newsletterAdditionalList && this.newsletterAdditionalList.checked
        ? `,${this.newsletterAdditionalList.value}`
        : '';

    const email = encodeURIComponent(emailValue);
    const name = encodeURIComponent(nameValue);
    const newsletterRodo = encodeURIComponent(newsletterRodoValue);
    const newsletterLists = encodeURIComponent(newsletterListsValues);

    const httpRequest = new XMLHttpRequest();

    httpRequest.open('POST', '/wp-admin/admin-ajax.php');
    httpRequest.setRequestHeader(
      'Content-Type',
      'application/x-www-form-urlencoded',
    );

    httpRequest.send(
      `action=register-email-to-mailerlite&name=${name}&email=${email}&lists=${newsletterLists}&rodo=${newsletterRodo}`,
    );

    httpRequest.onreadystatechange = () => {
      const DONE = 4;
      const OK = 200;

      this.button.disabled = false;
      this.submitForm.classList.remove('is-loading');

      if (httpRequest.readyState === DONE) {
        const response = JSON.parse(httpRequest.responseText);
        console.log(response.status);

        if (httpRequest.status === OK) {
          if (response.status === OK) {
            this.responseMessage(
              'Elegancko, a teraz biegnij na maila, żeby potwierdzić swój zapis!',
            );
            this.responseTextElement.classList.add('is-success');
          } else {
            this.responseMessage(response.error);
            // eslint-disable-next-line no-use-before-define
            console.log(`Error: ${response.error}`);
          }
        } else {
          this.responseMessage(httpRequest.responseText.error);
          // eslint-disable-next-line no-use-before-define
          console.log(`Error: ${httpRequest.status}`);
        }
      }
    };
  }

  responseMessage(msg) {
    this.responseTextElement.innerHTML += `${msg}<br/>`;
  }
}

export default NewsletterForm;
