class Neon {
  constructor(selector) {
    this.container = selector;
    this.type = this.container.classList.contains('c-neon--right')
      ? 'right'
      : 'left';
    this.items = this.container.querySelectorAll('.c-neon__text');
    this.list = this.container.querySelector('.c-neon__content');
    this.leftSideOfContainer = this.container.getBoundingClientRect().left;
    this.rightSideOfContainer = this.container.getBoundingClientRect().right;
    this.currentLeftValue = 0;
    this.currentRightValue = 0;
    this.elementWidth = this.items[0].offsetWidth;

    this.init();
  }

  init() {
    const elSize = `${this.elementWidth}px`;
    for (let i = 0; i < this.items.length; i += 1) {
      this.items[i].style.width = elSize;
    }

    // Kick off for the animation function.
    window.setInterval(() => {
      if (this.type === 'left') {
        this.animationLoopLeft();
      } else {
        this.animationLoopRight();
      }
    }, 30);
  }

  /*
    Looks at first item in the list and checks if it goes out of the visible area
    by comparing the right position of the first list item to the left position
    of the containing element.
  */
  animationLoopLeft() {
    const firstListItem = this.list.querySelector('.c-neon__text:first-child');
    const rightSideOfFirstItem = Math.round(
      firstListItem.getBoundingClientRect().right,
    );

    /*
      If first list item is out of viewable area, move it to the end of the list.
      Also, set the current left value to -1 so we won't stutter.
    */
    if (rightSideOfFirstItem === this.leftSideOfContainer) {
      this.currentLeftValue = -1;
      this.list.appendChild(firstListItem);
    }

    // The part this keeps it all going: animating the margin left value of the list.
    this.list.style.marginLeft = `${this.currentLeftValue}px`;
    this.currentLeftValue -= 1;
  }

  animationLoopRight() {
    const firstListItem = this.list.querySelector('.c-neon__text:first-child');
    const leftSideOfFirstItem = Math.round(
      firstListItem.getBoundingClientRect().left,
    );

    if (leftSideOfFirstItem === this.rightSideOfContainer) {
      this.currentRightValue = -50;
      this.list.appendChild(firstListItem);
    }

    this.list.style.transform = `translateX(${this.currentRightValue}px)`;
    this.currentRightValue += 1;
  }
}

export default Neon;
