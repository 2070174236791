/*
  Project: Artfreak
  Author: Ewa Karaszkiewicz
 */

import Nav from './modules/nav';
import ScrollToTop from './modules/scroll-to-top';
import NewsletterForm from './modules/newsletter-form';
import Slider from './modules/slider';
import CopyValue from './modules/copy-value';
import Neon from './modules/neon';
import RevealText from './modules/reveal-text';
import Accordion from './modules/accordion';

/* eslint-disable */
const navigation = document.querySelector('.js-toggle-nav');
if (navigation !== null) {
  new Nav(navigation);
}

const scroll2top = document.querySelector('.js-scroll-to-top');
if (scroll2top !== null) {
  new ScrollToTop(scroll2top);
}
/* eslint-enable */

const newsletterForms = document.querySelectorAll('.c-newsletter-form');
if (newsletterForms !== null) {
  for (let i = 0; i < newsletterForms.length; i += 1) {
    /* eslint-disable */
    new NewsletterForm(newsletterForms[i]);
    /* eslint-enable */
  }
}

const sliders = document.querySelectorAll('.js-slider');
if (sliders !== null) {
  for (let i = 0; i < sliders.length; i += 1) {
    /* eslint-disable */
    new Slider(sliders[i]);
    /* eslint-enable */
  }
}

const copyContentElements = document.querySelectorAll('.js-copy-value');
if (copyContentElements !== null) {
  for (let i = 0; i < copyContentElements.length; i += 1) {
    /* eslint-disable */
    new CopyValue(copyContentElements[i]);
    /* eslint-enable */
  }
}

const neonElements = document.querySelectorAll('.js-neon');
if (neonElements !== null) {
  for (let i = 0; i < neonElements.length; i += 1) {
    /* eslint-disable */
    new Neon(neonElements[i]);
    /* eslint-enable */
  }
}

const revealElements = document.querySelectorAll('.js-reveal-btn');
if (revealElements !== null) {
  for (let i = 0; i < revealElements.length; i += 1) {
    /* eslint-disable */
    new RevealText(revealElements[i]);
    /* eslint-enable */
  }
}

const accordions = document.querySelectorAll('.js-accordion');
if (accordions !== null) {
  for (let i = 0; i < accordions.length; i += 1) {
    /* eslint-disable */
    new Accordion(accordions[i]);
    /* eslint-enable */
  }
}

/* eslint-disable */
Array.prototype.forEach.call(
  document.querySelectorAll('[remove-orphans]'),
  function (el, i) {
    const string = el.innerHTML;
    el.innerHTML = string.replace(/(\s)([\S]{1,2})[\s]+/g, '$1$2&nbsp;');
  },
);
/* eslint-enable */
