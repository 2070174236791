import Glide from '@glidejs/glide';

class Slider {
  constructor(selector) {
    this.element = selector;
    this.args = this.argsAssign();

    this.init();
  }

  init() {
    const slider = new Glide(this.element, this.args);

    slider.mount();
  }

  argsAssign() {
    const classes = this.element.classList;
    let arg = '';

    if (classes.contains('c-instabar__slider')) {
      arg = {
        type: 'slider',
        startAt: 0,
        perView: 6,
        gap: 0,
        bound: true,
        breakpoints: {
          440: {
            perView: 1.5,
          },
          800: {
            perView: 2.5,
          },
          1200: {
            perView: 4,
          },
        },
      };
    }
    if (classes.contains('c-last-posts__slider')) {
      arg = {
        type: 'slider',
        perView: 3,
        gap: 40,
        bound: true,
        breakpoints: {
          440: {
            perView: 1.1,
            gap: 20,
          },
          800: {
            perView: 1.5,
            gap: 20,
          },
          1200: {
            perView: 2.5,
            gap: 20,
          },
        },
      };
    }
    if (classes.contains('c-ft-products__slider')) {
      arg = {
        perView: 4,
        peek: { before: 60, after: 60 },
        autoplay: 4000,
        animationDuration: 1700,
        animationTimingFunc: 'cubic-bezier(0.165, 0.840, 0.440, 1)',
        type: 'carousel',
        gap: 40,
        breakpoints: {
          440: {
            perView: 1.2,
            gap: 20,
          },
          800: {
            perView: 2.2,
            gap: 20,
          },
          1200: {
            perView: 3.2,
          },
        },
      };
    }
    if (classes.contains('c-ft-content__slider')) {
      arg = {
        type: 'slider',
        startAt: 0,
        perView: 1,
        gap: 0,
        bound: true,
      };
    }
    if (classes.contains('c-testimonials__slider')) {
      arg = {
        type: 'carousel',
        startAt: 1,
        perView: 2.8,
        gap: 33,
        // autoplay: 4000,
        animationDuration: 800,
        animationTimingFunc: 'cubic-bezier(0.165, 0.840, 0.440, 1)',
        bound: true,
        focusAt: 'center',
        breakpoints: {
          600: {
            perView: 1,
            gap: 10,
            peek: {
              before: 44,
              after: 44,
            },
          },
          800: {
            perView: 2.1,
            gap: 20,
          },
          1200: {
            perView: 2.6,
            gap: 20,
          },
        },
      };
    }
    if (classes.contains('c-product-gallery__slider')) {
      arg = {
        type: 'slider',
        perView: 1,
        gap: 0,
        bound: true,
        peek: {
          before: 0,
          after: 0,
        },
        breakpoints: {
          500: {
            perView: 1,
            gap: 20,
            peek: {
              before: 84,
              after: 84,
            },
          },
          800: {
            perView: 2,
            gap: 20,
            peek: {
              before: 84,
              after: 84,
            },
          },
        },
      };
    }
    if (classes.contains('c-team__slider')) {
      arg = {
        startAt: 0,
        perView: 5,
        gap: 80,
        bound: true,
        autoplay: 4000,
        animationDuration: 1700,
        animationTimingFunc: 'cubic-bezier(0.165, 0.840, 0.440, 1)',
        type: 'carousel',
        breakpoints: {
          400: {
            perView: 1.9,
          },
          600: {
            perView: 2.5,
          },
          950: {
            perView: 3.5,
          },
          1200: {
            perView: 4.5,
          },
        },
      };
    }
    if (classes.contains('c-timeline__slider')) {
      arg = {
        type: 'slider',
        perView: 3,
        gap: 40,
        bound: true,
        breakpoints: {
          600: {
            perView: 1,
            gap: 20,
          },
          800: {
            perView: 2,
            gap: 20,
          },
          1200: {
            perView: 3,
            gap: 20,
          },
        },
      };
    }
    if (classes.contains('c-price-comparision__slider')) {
      arg = {
        type: 'carousel',
        perView: 3,
        gap: 40,
        bound: true,

        breakpoints: {
          600: {
            perView: 1,
            gap: 20,
            peek: {
              before: 44,
              after: 88,
            },
          },
          800: {
            perView: 2,
            gap: 20,
            peek: {
              before: 44,
              after: 44,
            },
          },
        },
      };
    }
    return arg;
  }
}

export default Slider;
