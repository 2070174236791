class CopyValue {
  constructor(selector) {
    this.element = selector;
    this.init();
  }

  init() {
    this.element.addEventListener('click', (e) => {
      e.preventDefault();
      this.handleCopyValue();
    });
  }

  handleCopyValue() {
    const copyText = this.element.dataset.value;
    navigator.clipboard.writeText(copyText).then(() => {
      const { element } = this;
      element.classList.toggle('copied');
      setTimeout(function () {
        element.classList.toggle('copied');
      }, 2000);
    });
  }
}

export default CopyValue;
